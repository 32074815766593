// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-concierge-js": () => import("./../../../src/pages/concierge.js" /* webpackChunkName: "component---src-pages-concierge-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-projects-p-24101-js": () => import("./../../../src/pages/projects/p24101.js" /* webpackChunkName: "component---src-pages-projects-p-24101-js" */),
  "component---src-pages-projects-p-2788-js": () => import("./../../../src/pages/projects/p2788.js" /* webpackChunkName: "component---src-pages-projects-p-2788-js" */),
  "component---src-pages-projects-p-69466-js": () => import("./../../../src/pages/projects/p69466.js" /* webpackChunkName: "component---src-pages-projects-p-69466-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-and-conditions-partner-js": () => import("./../../../src/pages/terms-and-conditions-partner.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-partner-js" */),
  "component---src-pages-terms-and-conditions-transporter-js": () => import("./../../../src/pages/terms-and-conditions-transporter.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-transporter-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transporter-js": () => import("./../../../src/pages/transporter.js" /* webpackChunkName: "component---src-pages-transporter-js" */)
}

